<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">景观风貌基础数据</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="MC"
                    label="景观风貌名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.MC"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="JGHJMS"
                    label="景观风貌描述"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JGHJMS"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="FXMS"
                    label="位置描述"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.FXMS"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SS"
                    label="位置坐标"
                    class="from-item-block"
                >
                    <div class="location">
                        <div class="item">
                            <span>经度：</span>
                            <el-input
                                v-model="formdata.dataDic.JD"
                                :disabled="true"
                                @input="toDecimal($event, 'JD', 3, 5)"
                                style="width: calc(100% - 50px)"
                            ></el-input>
                        </div>
                        <div class="item">
                            <span>纬度：</span>
                            <el-input
                                v-model="formdata.dataDic.WD"
                                :disabled="true"
                                @input="toDecimal($event, 'WD', 3, 5)"
                                style="width: calc(100% - 50px)"
                            ></el-input>
                        </div>
                        <div class="btn">
                            <el-button size="small" @click="showPickup = true"
                                >坐标拾取</el-button
                            >
                        </div>
                    </div>
                </el-form-item>
                <el-form-item
                    prop="FX"
                    label="拍摄方向"
                    class="from-item-block"
                >
                    <el-input-number
                        v-model="formdata.dataDic.FX"
                        @change="handleChange"
                        :disabled="!isEdit"
                        label="请输入拍摄方向"
                    ></el-input-number>
                    <span style="margin-left: 10px">度</span>
                </el-form-item>
                <el-form-item
                    prop="CJSJ"
                    label="拍摄时间"
                    class="from-item-block"
                >
                    <!-- :disabled="!isEdit" -->
                    <el-date-picker
                        v-model="formdata.dataDic.CJSJ"
                        :disabled="true"
                        :clearable="true"
                        type="date"
                        placeholder="请选择拍摄时间"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="BZ" label="备注" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.BZ"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="CLQK" label="照片" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :itemId="itemId"
                        :isEdit="isEdit"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :maxSize="500"
                        :limit="1"
                    ></basic-upload>
                </el-form-item>
                <el-form-item label="结构示意图">
                    <el-upload
                        :action="`${BASE_URL}UpLoad/FileSave?LJ=basicData`"
                        accept="image/png,image/jpg,image/jpeg,image/gif"
                        list-type="picture-card"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="jgsytFileList"
                        :limit="1"
                        :class="{ noneupload: jgsytFileList.length === 1 }"
                        :on-success="handleSuccess"
                        :disabled="!isEdit"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                </el-form-item>
            </el-form>
        </div>
        <coordinatePickup
            :editState="isEdit"
            :showPickupP.sync="showPickup"
            :coordinate.sync="jwd"
            @clickPoint="clickPoint"
            @areaItem="areaItem"
        ></coordinatePickup>
    </div>
</template>

<script>
import coordinatePickup from "@comp/map/coordinate_pickup";
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "jghjjcsj",
    mixins: [infoMixin],
    components: {
        coordinatePickup,
    },
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            saveButton: true,
            formdata: {
                itemCode: this.$route.query.ItemID,
                dataDic: {
                    glycbtid: this.heritageId,
                    CJSJ: this.common.getTimeYmdhms(), //拍照时间
                    YCZC: "", //遗产组成
                    FX: "", //拍照方向
                    MC: "",
                    JGHJMS: "",
                    FXMS: "",
                    SS: "",
                    BZ: "",
                    JD: "",
                    WD: "",
                    SYT: "",
                    JGSYT: "",
                    JGSYTMC: "",
                },
            },
            rules: {
                MC: [
                    {
                        required: true,
                        message: "请填写名称",
                        trigger: "change",
                    },
                ],
                FXMS: [
                    {
                        required: true,
                        message: "请填写位置描述",
                        trigger: "change",
                    },
                ],
                FX: [
                    {
                        required: true,
                        message: "请填写拍摄方向",
                        trigger: "change",
                    },
                ],
                CJSJ: [
                    {
                        required: true,
                        message: "请填写拍摄时间",
                        trigger: "change",
                    },
                ],
            },
            showPickup: false,
            jwd: "",
            jgsytFileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
        };
    },
    watch: {
        formdata: {
            deep: true,
            handler: function ({ metaJson }) {
                if (Array.isArray(metaJson) && metaJson.length > 0) {
                    this.formdata.dataDic.SYT = metaJson[0].path;
                }
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([""]),
        GetDataNext() {
            let value = this.formdata.dataDic.ZB.split(",");
            this.formdata.dataDic.JD = value[0];
            this.formdata.dataDic.WD = value[1];
            this.jwd = `${value[0]},${value[1]}`;
            if (this.formdata.dataDic.JGSYT) {
                let obj = {
                    name: this.formdata.dataDic.JGSYTMC,
                    url: window.DOWN_URL + this.formdata.dataDic.JGSYT,
                };
                this.jgsytFileList = [];
                this.jgsytFileList.push(obj);
            }
        },
        clickPoint() {
            let value = this.jwd.split(",");
            this.formdata.dataDic.ZB = this.jwd;
            this.formdata.dataDic.JD = value[0];
            this.formdata.dataDic.WD = value[1];
        },
        areaItem(item) {
            this.formdata.dataDic.YCZC = item.QYMC;
        },
        handleRemove(file, fileList) {
            this.fileList = [];
            this.formdata.dataDic.JGSYT = "";
            this.formdata.dataDic.JGSYTMC = "";
        },
        handlePreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleSuccess(res, file) {
            this.jgsytFileList.push(file);
            this.formdata.dataDic.JGSYT = res.ResultValue[0].FilePath_ex;
            this.formdata.dataDic.JGSYTMC = res.ResultValue[0].FileName;
        },
    },
};
</script>

<style>
.location {
    display: flex;
}
.location .item {
    flex: 1;
}
.location .btn {
    width: 76px;
    margin: 0 5px;
}
</style>
